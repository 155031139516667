import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LogoIcon from './LogoSvg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: '85px', paddingBottom: '65px' },
  description: {
    text: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
    width: '306px',
    height: '96px',
  },
}));

const DescWithLogo = () => {
  const classes = useStyles();
  /*const theme = useTheme();*/
  /*const isSm = useMediaQuery(theme.breakpoints.up('sm'));*/
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction={'row'}
        justify={'flex-start'}
        alignItems={'center'}
        spacing={3}
        className={classes.root}
      >
        <Grid item style={{ paddingRight: '147px' }}>
          <LogoIcon width={'295px'} />
        </Grid>
        <Grid item>
          <Typography className={classes.description}>
            {t('descriptionHeader')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DescWithLogo;
