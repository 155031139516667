import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { ResourceDto } from '../../../../api/api';

const useStyles = makeStyles({
  title: {
    fontSize: '24px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    lineHeight: '29px',
    marginTop: '32px',
    marginBottom: '32px',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: '29px',
    marginTop: '32px',
    marginBottom: '32px',
    color: '#000',
    opacity: 0.6,
  },
  table: {
    /*maxWidth: '586px',*/
    '& th': {
      padding: '24px 0',
    },
  },
  tableHead: {
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#000',
    opacity: 0.45,
  },
  tableCellBody: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#000',
  },
});

const Resources: FC<{
  resources: ResourceDto[];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ resources, refEl }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Typography id={'resources'} ref={refEl} className={classes.title}>
        Ресурсы
      </Typography>
      <Typography className={classes.subTitle}>
        Команда работы над сервисом
      </Typography>
      <TableContainer>
        <Table className={classes.table} size="medium">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>Роль</TableCell>
              <TableCell align="right">Кол-во специалистов</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((row) => (
              <TableRow key={row?.position?.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCellBody}
                >
                  {row?.position?.name}
                </TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Resources;
