import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  BenchmarkDto,
  EffectDto,
  PotionDto,
  PotionKnowledgeDto,
  PotionMechanicsDto,
  PotionsService,
  ResourceDto,
} from '../../api/api';
import BackBtn from '../common/BackBtn';
import BaseContent from './BaseContent/BaseContent';
import clockIcon from '../../assets/images/time.svg';
import starIcon from '../../assets/images/star.svg';
import userIcon from '../../assets/images/profile.svg';
import downloadIcon from './SideBar/icons/downloadIcon.png';
import figmaIcon from './SideBar/icons/figma.svg';
import githubIcon from './SideBar/icons/github.svg';

import SideBar from './SideBar/SideBar';

export type TitlesData =
  | PotionDto['description']
  | EffectDto[]
  | BenchmarkDto[]
  | PotionDto['toRemember']
  | ResourceDto[]
  | PotionDto['developmentProcess']
  | PotionDto['implementationPeriod']
  | PotionMechanicsDto[]
  | PotionKnowledgeDto[];
export type TitlesValue = {
  name: string;
  data?: TitlesData;
};
export type Titles = {
  [key: string]: TitlesValue;
};

export type SideBarButtons = {
  text: string;
  icon: JSX.Element;
  link:
    | PotionDto['documentLink']
    | PotionDto['gitHubLink']
    | PotionDto['figmaLink'];
}[];

export type SideBarInfo = { icon: JSX.Element; text: string }[];

const Potion: FC = () => {
  const { potionId } = useParams<{ potionId: string }>();
  const [potion, setPotion] = useState<PotionDto>({});
  const { t, i18n } = useTranslation();
  /*console.log(potion);*/
  /** refs for scroll */
  const descriptionRef = useRef<HTMLDivElement>(null);
  const effectsRef = useRef<HTMLDivElement>(null);
  const benchmarksRef = useRef<HTMLDivElement>(null);
  const mechanicsRef = useRef<HTMLDivElement>(null);
  const toRememberRef = useRef<HTMLDivElement>(null);
  const resourcesRef = useRef<HTMLDivElement>(null);
  const developmentProcessRef = useRef<HTMLDivElement>(null);
  const implementationPeriodRef = useRef<HTMLDivElement>(null);
  const researchesRef = useRef<HTMLDivElement>(null);
  const [anchorId, setAnchorId] = useState('description');
  const allRefs = useMemo(
    () => [
      descriptionRef,
      effectsRef,
      benchmarksRef,
      mechanicsRef,
      toRememberRef,
      resourcesRef,
      developmentProcessRef,
      implementationPeriodRef,
      researchesRef,
    ],
    []
  );
  const getCurrentAnchorId = useCallback(
    () =>
      allRefs.map(
        (ref) =>
          ref.current &&
          ref.current.getBoundingClientRect().y < 32 &&
          setAnchorId(ref.current.id)
      ),
    [allRefs]
  );
  useEffect(() => {
    /** get data */
    PotionsService.potion1({ id: +potionId }).then((res) => setPotion(res));
    /** listen scroll */
    window.addEventListener('scroll', () => getCurrentAnchorId());
    return () => {
      window.removeEventListener('scroll', () => getCurrentAnchorId());
    };
  }, [potionId, getCurrentAnchorId, setPotion, i18n.language]);

  /** create titles with data for Sidebar */
  const titles: Titles = {
    description: { name: t('descriptionTitle'), data: potion.description },
    effects: { name: t('effectsTitle'), data: potion.effects },
    benchmarks: { name: t('benchmarksTitle'), data: potion.benchmarks },
    mechanics: { name: t('mechanicsTitle'), data: potion.mechanics },
    toRemember: { name: t('toRememberTitle'), data: potion.toRemember },
    resources: { name: t('resourcesTitle'), data: potion.resources },
    developmentProcess: {
      name: t('developmentProcessTitle'),
      data: potion.developmentProcess,
    },
    implementationPeriod: {
      name: t('implementationPeriodTitle'),
      data: potion.implementationPeriod,
    },
    researches: { name: t('researchTitle'), data: potion.researches },
  };
  const sideBarButtons: SideBarButtons = [
    {
      text: t('docDesign'),
      icon: <img src={downloadIcon} alt={'download'} />,
      link: potion.documentLink,
    },
    {
      text: 'Github',
      icon: <img src={githubIcon} alt={'Github'} />,
      link: potion.gitHubLink,
    },
    {
      text: 'Figma',
      icon: <img src={figmaIcon} alt={'Figma'} />,
      link: potion.figmaLink,
    },
  ];
  const info: SideBarInfo = [
    {
      icon: <img src={clockIcon} alt={'duration'} />,
      text: `2 ${t('months')}`,
    },
    {
      icon: <img src={userIcon} alt={'quantity'} />,
      text: `6-8 ${t('people')}`,
    },
    { icon: <img src={starIcon} alt={'difficulty'} />, text: t('easy') },
  ];
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid item xs={isMd ? 12 : 10}>
      <BackBtn />
      <Grid container justify={'center'}>
        <Grid item xs={3}>
          <SideBar
            image={potion.image}
            titles={titles}
            sideBarButtons={sideBarButtons}
            potionName={potion.name}
            info={info}
            anchorId={anchorId}
          />
        </Grid>
        <Grid item xs={9}>
          <BaseContent refEls={allRefs} potion={potion} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Potion;
