// Блок с общей информации (категория, заголовок, описание)
import { Divider, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { CategoryDto, PotionDto } from '../../../../api/api';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import profile from '../../../../assets/images/profile.svg';
import time from '../../../../assets/images/time.svg';
import star from '../../../../assets/images/star.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    category: {
      fontSize: '12px',
      fontFamily: 'Montserrat, sans-serif',
      marginBottom: '7px',
    },
    divider: {
      height: '1px',
      background: '#1B1B1B',
      marginBottom: '24px',
    },
    icon: {
      width: '18px',
      height: '18px',
      // marginRight: '4px',
    },
    grid: {
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
      marginTop: '2px',
      marginBottom: '11px',
    },
    description: {
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
    },
    name: {
      fontSize: '42px',
      fontFamily: 'Montserrat, sans-serif',
      marginBottom: '6px',
    },
  })
);

const Description: FC<{
  categoryName: CategoryDto['name'];
  name: PotionDto['name'];
  description: PotionDto['description'];
  difficulty: PotionDto['difficulty'];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ categoryName, name, description, difficulty, refEl }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item style={{ marginBottom: '32px' }}>
      <Typography className={classes.category}>{categoryName}</Typography>
      <Divider className={classes.divider} />
      <h1 id={'description'} ref={refEl} className={classes.name}>
        {name?.toUpperCase()}
      </h1>
      <Grid
        container
        className={classes.grid}
        direction="row"
        justify="flex-start"
        spacing={3}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item style={{ height: '26px' }}>
              <img className={classes.icon} alt="placeholder" src={time} />
            </Grid>
            <Grid item>1 месяц</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item style={{ height: '26px' }}>
              <img className={classes.icon} alt="placeholder" src={profile} />
            </Grid>
            <Grid item>6-8 человек</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item style={{ height: '26px' }}>
              <img className={classes.icon} alt="placeholder" src={star} />
            </Grid>
            <Grid item>{t(difficulty?.toLowerCase() || '')}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.description}>{description}</Typography>
    </Grid>
  );
};

export default Description;
