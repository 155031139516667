import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import FiltersBlock, { Filter } from '../FilterBlock/FiltersBlock';
import {
  CategoryDto,
  CategoriesService,
  EffectDto,
  EffectsService,
  PotionDto,
  PotionsService,
} from '../../api/api';
import Potions from '../Potions/Potions';
import { useQuery } from '../common/useQuery';

const Main = () => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  /** Get Categories */
  const categoryTitle = useMemo(() => ({ id: 0, name: `${t('categories')}` }), [
    t,
  ]);
  const [categoryArr, setCategoryArr] = useState<CategoryDto[]>([
    categoryTitle,
  ]);
  useEffect(() => {
    CategoriesService.categories().then((catArrRes) => {
      catArrRes.unshift(categoryTitle);
      setCategoryArr(catArrRes);
    });
  }, [categoryTitle]);

  /** Get Effects */
  const effectsTitle = useMemo(() => ({ id: 0, name: `${t('effects')}` }), [t]);
  const [effectArr, setEffectArr] = useState<EffectDto[]>([effectsTitle]);
  useEffect(() => {
    EffectsService.effects().then((effArrRes) => {
      effArrRes.unshift(effectsTitle);
      setEffectArr(effArrRes);
    });
  }, [effectsTitle]);

  const difficultyArr: Filter[] = useMemo(
    () => [
      { id: 0, name: `${t('difficulty')}`, value: '' },
      { id: 1, name: `${t('easy')}`, value: 'EASY' },
      { id: 2, name: `${t('medium')}`, value: 'MEDIUM' },
      { id: 3, name: `${t('hard')}`, value: 'HARD' },
    ],
    [t]
  );

  const [filter, setFilter] = useState<{
    category: CategoryDto['id'];
    effect: EffectDto['id'];
    difficulty: Filter['id'];
  }>({
    category: query.get('category')
      ? parseInt(query.get('category') as string)
      : categoryArr[0].id,
    effect: query.get('effect')
      ? parseInt(query.get('effect') as string)
      : effectArr[0].id,
    difficulty: query.get('difficulty')
      ? parseInt(query.get('difficulty') as string)
      : difficultyArr[0].id,
  });

  const [list, setList] = useState<{ data: PotionDto[]; total: number }>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    PotionsService.potion({
      ...(filter.category ? { categoryId: filter.category } : {}),
      ...(filter.effect ? { effectId: filter.effect } : {}),
      ...(filter.difficulty
        ? { difficulty: difficultyArr[filter.difficulty].value }
        : {}),
    }).then((res) => setList(res));
  }, [
    difficultyArr,
    filter.category,
    filter.difficulty,
    filter.effect,
    i18n.language,
  ]);

  return (
    <Grid container spacing={1} style={{ overflow: 'hidden' }}>
      <FiltersBlock
        categoryArr={categoryArr}
        effectArr={effectArr}
        difficultyArr={difficultyArr}
        filter={filter}
        setFilter={setFilter}
      />
      <Potions list={list} />
    </Grid>
  );
};

export default Main;
