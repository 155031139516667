// Исследования по теме
import { Divider, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { PotionKnowledgeDto } from '../../../../api/api';
/*import clockIcon from '../../../../assets/images/time.svg';*/

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '20px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    lineHeight: '29px',
    marginTop: '32px',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: '10px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    lineHeight: 1.5,
    marginTop: '30px',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    paddingBottom: '10px',
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    marginBottom: '9px',
  },
  researchName: {
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    paddingBottom: '24px',
  },
  researchInfo: {
    fontSize: '10px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 'bold',
    lineHeight: '1',
    textTransform: 'uppercase',
    color: '#ABABAB',
  },
  researchImage: {
    width: '100%',
    minWidth: '200px',
    height: '256px',
    paddingBottom: '48px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
}));

const Researches: FC<{
  researches: PotionKnowledgeDto[];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ researches, refEl }) => {
  const classes = useStyles();
  /*console.log(researches);*/
  return (
    <Grid container>
      <Grid item xs={9}>
        <Typography id={'researches'} ref={refEl} className={classes.title}>
          Исследования по теме
        </Typography>
      </Grid>
      {researches.map((r) => (
        <Grid item key={r.id} xs={9}>
          <Typography className={classes.subTitle}>researches</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.researchName}>{r.name}</Typography>
          {/*<Grid
            container
            className={classes.researchInfo}
            spacing={2}
            alignItems={'center'}
          >
            <Grid item>
              <img src={clockIcon} alt={''} />
            </Grid>
            <Grid item>20 минут</Grid>
          </Grid>*/}
          <Link href={r.link} target={'_blank'}>
            <Grid container>
              <Grid
                item
                style={{
                  backgroundImage: `url(${r.image})`,
                }}
                className={classes.researchImage}
              />
            </Grid>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Researches;
