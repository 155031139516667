import { Grid } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';
import arrowDown from './arrowDown.svg';
import { Filter } from './FiltersBlock';

export const renderFilterSelect = (
  filterState: Filter['id'],
  filtersArr: Filter[],
  setFilterState: (Filter: Filter['id']) => void,
  classes: ClassNameMap<'all' | 'select'>
) => (
  <Grid item>
    <select
      className={classes.select}
      value={filterState}
      style={{
        backgroundImage: `url(${arrowDown})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '140px center',
      }}
      onChange={(e) => setFilterState(filtersArr[e.target.selectedIndex].id)}
    >
      {filtersArr.map((c) => (
        <option key={c.id} id={String(c.id)} value={c.id}>
          {c.name}
        </option>
      ))}
    </select>
  </Grid>
);
