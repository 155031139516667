import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './component/Footer/Footer';
import Main from './component/Main/Main';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';
import { serviceOptions } from './api/api';
import Potion from './component/Potion/Potion';
import Header from './component/Header/Header';
import { Grid } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Inter, Montserrat, sans-serif',
  },
  palette: {
    text: { primary: '#1B1B1B', secondary: '#6A6A6A' },
    background: { default: '#F7CE24' },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const App = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem('language') || 'ru'
  );
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  serviceOptions.axios = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 1000,
    headers: { Locale: language },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Switch>
              <Route path="/potions/:potionId">
                <Header language={language} setLanguage={setLanguage} />
                <Grid
                  container
                  spacing={1}
                  alignItems={'center'}
                  direction={'column'}
                >
                  <Potion />
                </Grid>
              </Route>
              <Route path="/">
                <Header
                  isFullHeader
                  language={language}
                  setLanguage={setLanguage}
                />
                <Main />
              </Route>
            </Switch>
          </div>
        </Router>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
