import { FC } from 'react';
const LogoIcon: FC<{ width: string }> = ({ width }) => (
  <svg
    width={width}
    viewBox="0 0 295 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.656 1.408C35.3467 1.408 40.304 2.34666 44.528 4.224C48.752 6.10133 52.008 8.8 54.296 12.32C56.584 15.84 57.728 19.9467 57.728 24.64C57.728 29.3333 56.584 33.44 54.296 36.96C52.008 40.48 48.752 43.1787 44.528 45.056C40.304 46.9333 35.3467 47.872 29.656 47.872H20.768V63.008H0V1.408H29.656ZM28.336 31.856C31.152 31.856 33.264 31.24 34.672 30.008C36.08 28.7173 36.784 26.928 36.784 24.64C36.784 22.352 36.08 20.592 34.672 19.36C33.264 18.0693 31.152 17.424 28.336 17.424H20.768V31.856H28.336Z"
      fill="#1B1B1B"
    />
    <path
      d="M97.6944 64.416C91.0651 64.416 85.1104 63.0373 79.8304 60.28C74.5504 57.5227 70.4144 53.7093 67.4224 48.84C64.4304 43.912 62.9344 38.368 62.9344 32.208C62.9344 26.048 64.4304 20.5333 67.4224 15.664C70.4144 10.736 74.5504 6.89333 79.8304 4.136C85.1104 1.37867 91.0651 0 97.6944 0C104.324 0 110.278 1.37867 115.558 4.136C120.838 6.89333 124.974 10.736 127.966 15.664C130.958 20.5333 132.454 26.048 132.454 32.208C132.454 38.368 130.958 43.912 127.966 48.84C124.974 53.7093 120.838 57.5227 115.558 60.28C110.278 63.0373 104.324 64.416 97.6944 64.416ZM97.6944 47.608C100.276 47.608 102.593 46.992 104.646 45.76C106.758 44.4693 108.43 42.68 109.662 40.392C110.894 38.0453 111.51 35.3173 111.51 32.208C111.51 29.0987 110.894 26.4 109.662 24.112C108.43 21.7653 106.758 19.976 104.646 18.744C102.593 17.4533 100.276 16.808 97.6944 16.808C95.1131 16.808 92.7664 17.4533 90.6544 18.744C88.6011 19.976 86.9584 21.7653 85.7264 24.112C84.4944 26.4 83.8784 29.0987 83.8784 32.208C83.8784 35.3173 84.4944 38.0453 85.7264 40.392C86.9584 42.68 88.6011 44.4693 90.6544 45.76C92.7664 46.992 95.1131 47.608 97.6944 47.608Z"
      fill="#1B1B1B"
    />
    <path
      d="M152.509 17.512H134.469V1.408H191.317V17.512H173.277V63.008H152.509V17.512Z"
      fill="#1B1B1B"
    />
    <path d="M196.711 1.408H217.479V63.008H196.711V1.408Z" fill="#1B1B1B" />
    <path
      d="M259.773 64.416C253.143 64.416 247.189 63.0373 241.909 60.28C236.629 57.5227 232.493 53.7093 229.501 48.84C226.509 43.912 225.013 38.368 225.013 32.208C225.013 26.048 226.509 20.5333 229.501 15.664C232.493 10.736 236.629 6.89333 241.909 4.136C247.189 1.37867 253.143 0 259.773 0C266.402 0 272.357 1.37867 277.637 4.136C282.917 6.89333 287.053 10.736 290.045 15.664C293.037 20.5333 294.533 26.048 294.533 32.208C294.533 38.368 293.037 43.912 290.045 48.84C287.053 53.7093 282.917 57.5227 277.637 60.28C272.357 63.0373 266.402 64.416 259.773 64.416ZM259.773 47.608C262.354 47.608 264.671 46.992 266.725 45.76C268.837 44.4693 270.509 42.68 271.741 40.392C272.973 38.0453 273.589 35.3173 273.589 32.208C273.589 29.0987 272.973 26.4 271.741 24.112C270.509 21.7653 268.837 19.976 266.725 18.744C264.671 17.4533 262.354 16.808 259.773 16.808C257.191 16.808 254.845 17.4533 252.733 18.744C250.679 19.976 249.037 21.7653 247.805 24.112C246.573 26.4 245.957 29.0987 245.957 32.208C245.957 35.3173 246.573 38.0453 247.805 40.392C249.037 42.68 250.679 44.4693 252.733 45.76C254.845 46.992 257.191 47.608 259.773 47.608Z"
      fill="#1B1B1B"
    />
    <path
      d="M60.72 78.408V140.008H43.648L20.24 112.024V140.008H0V78.408H17.072L40.48 106.392V78.408H60.72Z"
      fill="#1B1B1B"
    />
    <path
      d="M93.6946 141.416C88.5906 141.416 83.6039 140.859 78.7346 139.744C73.9239 138.571 69.9639 137.016 66.8546 135.08L73.5426 119.944C76.4759 121.645 79.7612 123.024 83.3986 124.08C87.0359 125.077 90.5266 125.576 93.8706 125.576C96.7452 125.576 98.7986 125.312 100.031 124.784C101.263 124.197 101.879 123.347 101.879 122.232C101.879 120.941 101.057 119.973 99.4146 119.328C97.8306 118.683 95.1906 117.979 91.4946 117.216C86.7426 116.219 82.7826 115.163 79.6146 114.048C76.4466 112.875 73.6892 110.997 71.3426 108.416C68.9959 105.776 67.8226 102.227 67.8226 97.768C67.8226 93.896 68.9079 90.376 71.0786 87.208C73.2492 84.04 76.4759 81.5467 80.7586 79.728C85.0999 77.9093 90.3506 77 96.5106 77C100.735 77 104.871 77.4693 108.919 78.408C113.025 79.288 116.633 80.608 119.743 82.368L113.495 97.416C107.452 94.3653 101.732 92.84 96.3346 92.84C90.9959 92.84 88.3266 94.1307 88.3266 96.712C88.3266 97.944 89.1186 98.8827 90.7026 99.528C92.2866 100.115 94.8972 100.76 98.5346 101.464C103.228 102.344 107.188 103.371 110.415 104.544C113.641 105.659 116.428 107.507 118.775 110.088C121.18 112.669 122.383 116.189 122.383 120.648C122.383 124.52 121.297 128.04 119.127 131.208C116.956 134.317 113.7 136.811 109.359 138.688C105.076 140.507 99.8546 141.416 93.6946 141.416Z"
      fill="#1B1B1B"
    />
    <path
      d="M147.984 104.008H255.984V114.008H147.984V104.008Z"
      fill="#1B1B1B"
    />
  </svg>
);
export default LogoIcon;
