import { Box, Divider, Grid, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { PotionDto } from '../../../api/api';
import { SideBarButtons, SideBarInfo, Titles } from '../Potion';
import activeVertLine from './icons/activeVertLine.svg';
import vertLine from './icons/vertLine.svg';
import repostArrow from './icons/repostArrow.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholder: {
      maxWidth: '282px',
      width: '100%',
    },
    title: {
      color: theme.palette.text.primary,
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      fontSize: '16px',
      textTransform: 'uppercase',
      lineHeight: '19.5px',
    },
    ulBlock: {
      listStyleType: 'none',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      style: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      paddingInlineStart: 0,
      '& > li': {
        paddingLeft: '17px',
        backgroundImage: `url(${vertLine})`,
        backgroundSize: '2px',
        backgroundRepeat: 'no-repeat',
      },
      /*'& > li:active': {
        background: `url(${activeVertLine}), url(${vertLine})`,
        backgroundSize: '2px 24px, 2px',
        backgroundRepeat: 'no-repeat',
      },*/
      '& > li.active': {
        background: `url(${activeVertLine}), url(${vertLine})`,
        backgroundSize: '2px 24px, 2px',
        backgroundRepeat: 'no-repeat',
      },
      '& > li > a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        fontWeight: 600,
      },
      '& > li > a.active': {
        color: theme.palette.text.primary,
        fontWeight: 600,
      },
      '& > li > a:hover': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    },
    buttonLink: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    button: {
      padding: '18px 22px',
      borderColor: '#DBDBDB',
      borderStyle: 'solid',
      borderWidth: '1px',
      '&:hover': {
        backgroundColor: '#f6f6f6',
      },
    },
    buttonText: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      color: '#000',
    },
    buttonIcon: {
      padding: 0,
      color: theme.palette.text.secondary,
      height: '20px',
    },
    divider: {
      marginTop: '16px',
      marginBottom: '16px',
    },
    info: {
      color: theme.palette.text.secondary,
    },
    infoText: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '16px',
    },
  })
);

const SideBar: FC<{
  image: PotionDto['image'];
  titles: Titles;
  sideBarButtons: SideBarButtons;
  potionName?: string;
  info: SideBarInfo;
  anchorId: string;
}> = ({ image, titles, sideBarButtons, potionName, info, anchorId }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <img className={classes.placeholder} alt="placeholder" src={image} />
      </Grid>
      <br />
      <br />
      <Grid
        container
        direction={'column'}
        spacing={1}
        style={{ position: 'sticky', top: 0 }}
      >
        <Grid item>
          {/** LINKS */}
          <Grid container direction={'column'} style={{ maxWidth: '220px' }}>
            <Grid item>
              <Typography className={classes.title}>{potionName}</Typography>
              <ul className={classes.ulBlock}>
                {Object.keys(titles).map((title, index) => {
                  return (
                    <li
                      key={title}
                      className={title === anchorId ? 'active' : ''}
                    >
                      {titles[title].data && titles[title].data?.length !== 0 && (
                        <Link
                          key={index}
                          href={`#${title}`}
                          className={title === anchorId ? 'active' : ''}
                          noWrap
                          /*onClick={() =>
                            window.scrollTo({
                              behavior: 'smooth',
                            })
                          }*/
                        >
                          {titles[title].name}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </Grid>
            {/** BUTTONS */}
            <Grid item>
              <Grid
                container
                direction={'column'}
                spacing={1}
                alignItems={'flex-start'}
              >
                {sideBarButtons.map((b) => (
                  <Grid item key={b.text}>
                    {b.link && (
                      <Link
                        href={b.link}
                        target="_blank"
                        className={classes.buttonLink}
                      >
                        <Box className={classes.button}>
                          <Grid container spacing={2} alignItems={'center'}>
                            <Grid item className={classes.buttonText}>
                              {b.text}
                            </Grid>
                            <Grid item>
                              <Box className={classes.buttonIcon}>{b.icon}</Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Link>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Divider className={classes.divider} />
            </Grid>
            {/** INFO */}
            <Grid item>
              <Grid container direction={'column'} spacing={2}>
                {info.map((item, i) => (
                  <Grid item key={item.text}>
                    <Grid
                      container
                      alignItems={'center'}
                      justify={'space-between'}
                    >
                      <Grid item>
                        <Grid container spacing={2} className={classes.info}>
                          <Grid item>
                            <Box>{item.icon}</Box>
                          </Grid>
                          <Grid item className={classes.infoText}>
                            {item.text.toLowerCase()}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {i === 0 && <img src={repostArrow} alt={''} />}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
      </Grid>
    </>
  );
};

export default SideBar;
