import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core';
import logo from '../../../src/assets/images/logo.svg';
import facebook from '../../../src/assets/images/facebook.svg';
import youtube from '../../../src/assets/images/youtube.svg';
import behance from '../../../src/assets/images/behance.svg';
import vimeo from '../../../src/assets/images/vimeo.svg';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: '64px',
      color: '#1B1B1B',
      fontFamily: 'Montserrat, sans-serif',
    },
    box: {
      // padding: theme.spacing(2),
      textAlign: 'left',
    },
    menuList: {
      paddingTop: '16px',
    },
    menuItem: {
      paddingLeft: 0,
      paddingTop: '8px',
      '&:hover': {
        background: 'none',
        fontWeight: 'bold',
      },
      fontSize: '14px',
    },
    subTitle: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '12px',
    },
    title: {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontFamily: 'Montserrat, sans-serif',
    },
    logo: {
      paddingTop: '64px',
      margin: 'auto',
    },
    image: { height: 48, width: 128 },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    text: {
      fontSize: '12px',
    },
    social: {},
  })
);

const Footer = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div style={{ padding: '8px 8.5%' }}>
      <Typography className={classes.title}>
        Gamification<b>Lab</b>
      </Typography>
      <hr />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className={classes.box}>
              <Typography className={classes.subTitle}>
                {t('research')}
              </Typography>
              <MenuList className={classes.menuList}>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('users')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('education')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('avatar')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('gamification')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('technologies')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('bank')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">
                    {t('theory and methodology')}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className={classes.box}>
              <Typography className={classes.subTitle}>
                {t('projects')}
              </Typography>
              <MenuList className={classes.menuList}>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">Experiments</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">Avatar</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">Better App</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">Digiral Human</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">
                    Game Based Education
                  </Typography>
                </MenuItem>
              </MenuList>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className={classes.box}>
              <Typography className={classes.subTitle}>
                {t('laboratory')}
              </Typography>
              <MenuList className={classes.menuList}>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">Chains Concept</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">People</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('cooperation')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('internships')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">{t('contacts')}</Typography>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Typography variant="inherit">FAQ</Typography>
                </MenuItem>
              </MenuList>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className={classes.box}>
              <Typography className={classes.subTitle}>{t('video')}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.logo}>
          <Grid container spacing={3}>
            <Grid item xs>
              <ButtonBase className={classes.image}>
                <img className={classes.img} alt="sber" src={logo} />
              </ButtonBase>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.text}>
                <Trans i18nKey={'textFooter'} />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <IconButton aria-label="facebook">
                <img className={classes.social} alt="facebook" src={facebook} />
              </IconButton>
              <IconButton aria-label="youtube">
                <img className={classes.social} alt="youtube" src={youtube} />
              </IconButton>
              <IconButton aria-label="behance">
                <img className={classes.social} alt="behance" src={behance} />
              </IconButton>
              <IconButton aria-label="vimeo">
                <img className={classes.social} alt="vimeo" src={vimeo} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Footer;
