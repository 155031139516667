import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './i18n/ru.json';
import en from './i18n/en.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: en,
  ru: ru,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ru',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
      useSuspense: true,
    },
  });

export default i18n;
