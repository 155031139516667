import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import React, { Dispatch, FC, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import DescWithLogo from './DescWithLogo';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  fullHeader: { background: '#F7CE24' },
  shortHeader: { background: 'transparent' },
  title: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: '18px',
  },
  boldTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    fontSize: '18px',
  },
  search: {
    cursor: 'pointer',
  },
  language: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    fontSize: '11px',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  divider: {
    marginTop: '8px',
  },
  primaryTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    fontSize: '12px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  secondaryTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    fontSize: '12px',
    textDecoration: 'none',
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
}));

const Header: FC<{
  isFullHeader?: boolean;
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
}> = ({ isFullHeader = false, language, setLanguage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const match = useRouteMatch();

  const { t, i18n } = useTranslation();
  const onChangeLanguage = () => {
    if (language === 'ru') {
      setLanguage('en');
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    } else {
      setLanguage('ru');
      i18n.changeLanguage('ru');
      localStorage.setItem('language', 'ru');
    }
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        className={isFullHeader ? classes.fullHeader : classes.shortHeader}
        style={isSm ? { padding: '8px' } : { padding: '8px 8.5%' }}
      >
        <Grid item xs={12}>
          <Grid container justify={'space-between'}>
            <Typography className={classes.title}>
              GAMIFICATION<b className={classes.boldTitle}>LAB</b>
            </Typography>
            <Grid item>
              <Grid container alignItems={'center'}>
                <SearchIcon className={classes.search} />
                <Box className={classes.language} onClick={onChangeLanguage}>
                  {language}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <b className={classes.primaryTitle}>POTIONS</b>
              <Link to={`${match.url}`} className={classes.secondaryTitle}>
                G-MODEL
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={`${match.url}`}
                target="_blank"
                className={classes.secondaryTitle}
              >
                {t('catalog')}
              </Link>{' '}
              <Link
                to={`${match.url}`}
                target="_blank"
                className={classes.secondaryTitle}
              >
                FAQ
              </Link>
            </Grid>
          </Grid>
        </Grid>
        {isFullHeader && <DescWithLogo />}
      </Grid>
    </Grid>
  );
};

export default Header;
