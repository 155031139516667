/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class PotionsService {
  /**
   *
   */
  static potion(
    params: {
      /** Сколько записей пропустить */
      skip?: number;
      /** Количество записей на страницу */
      take?: number;
      /**  */
      categoryId?: number;
      /**  */
      effectId?: number;
      /**  */
      difficulty?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/potion';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );
      configs.params = {
        skip: params['skip'],
        take: params['take'],
        categoryId: params['categoryId'],
        effectId: params['effectId'],
        difficulty: params['difficulty'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static potion1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PotionDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/potion/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EffectsService {
  /**
   *
   */
  static effects(options: IRequestOptions = {}): Promise<EffectDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/effects';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CategoriesService {
  /**
   *
   */
  static categories(options: IRequestOptions = {}): Promise<CategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories';

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface ErrorDto {
  /** Код статуса ошибки */
  statusCode?: number;

  /** Описание ошибки */
  error?: string;

  /** Описание ошибок */
  errors?: object;

  /** Текст ошибки */
  message?: string;

  /** Стэк вызовов */
  trace?: string;
}

export interface PageResponseDto {
  /** Общее количество записей */
  total?: number;

  /**  */
  data?: string[];
}

export interface CategoryDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PotionListItemDto {
  /**  */
  id?: number;

  /**  */
  image?: string;

  /**  */
  name?: string;

  /**  */
  shortDescription?: string;

  /**  */
  category?: CategoryDto;

  /**  */
  difficulty?: string;
}

export interface EffectDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface BenchmarkDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface PositionDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface ResourceDto {
  /**  */
  quantity?: number;

  /**  */
  position?: PositionDto;
}

export interface MechanicsCategoryDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  color?: string;
}

export interface PotionMechanicsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  categories?: MechanicsCategoryDto[];

  /**  */
  link?: string;
}

export interface PotionKnowledgeDto {
  /**  */
  id?: number;

  /**  */
  image?: string;

  /**  */
  name?: string;

  /**  */
  link?: string;
}

export interface PotionDto {
  /**  */
  id?: number;

  /**  */
  image?: string;

  /**  */
  name?: string;

  /**  */
  shortDescription?: string;

  /**  */
  category?: CategoryDto;

  /**  */
  difficulty?: string;

  /**  */
  description?: string;

  /**  */
  toRemember?: string;

  /**  */
  developmentProcess?: string;

  /**  */
  implementationPeriod?: string;

  /**  */
  effects?: EffectDto[];

  /**  */
  benchmarks?: BenchmarkDto[];

  /**  */
  resources?: ResourceDto[];

  /**  */
  mechanics?: PotionMechanicsDto[];

  /**  */
  researches?: PotionKnowledgeDto[];

  /**  */
  figmaLink?: string;

  /**  */
  gitHubLink?: string;

  /**  */
  documentLink?: string;
}
