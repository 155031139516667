import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { PotionDto } from '../../../api/api';
import Description from './BaseInfo/Description';
import Benchmarks from './Benchmarks/Benchmarks';
import Mechanics from './Mechanics/Mechanics';
import Researches from './Researches/Researches';
import Resources from './Resources/Resources';
import Effects from './Effects/Effects';
import SimpleList from './SimpleList/SimpleList';
import ImplementationPeriod from './ImplementationPeriod/ImplementationPeriod';

const BaseContent: FC<{
  potion: PotionDto;
  refEls: React.RefObject<HTMLDivElement>[];
}> = ({ potion, refEls }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container direction={'column'} alignItems={'center'}>
      <Grid item xs={isMd ? 11 : 8}>
        <Description
          categoryName={potion.category?.name}
          name={potion.name}
          description={potion.description}
          difficulty={potion.difficulty}
          refEl={refEls[0]}
        />
        {potion.effects && potion.effects.length !== 0 && (
          <Effects effects={potion.effects} refEl={refEls[1]} />
        )}
        {potion.benchmarks && potion.benchmarks.length !== 0 && (
          <Benchmarks benchmarks={potion.benchmarks} refEl={refEls[2]} />
        )}
        {potion.mechanics && potion.mechanics.length !== 0 && (
          <Mechanics mechanics={potion.mechanics} refEl={refEls[3]} />
        )}
        {potion.toRemember && (
          <SimpleList
            titleId={'toRemember'}
            title={'О чем стоит помнить'}
            text={potion.toRemember}
            refEl={refEls[4]}
          />
        )}
        {potion.resources && potion.resources.length !== 0 && (
          <Resources resources={potion.resources} refEl={refEls[5]} />
        )}
        {potion.developmentProcess && (
          <SimpleList
            titleId={'developmentProcess'}
            title={'Процесс разработки'}
            text={potion.developmentProcess}
            refEl={refEls[6]}
          />
        )}
        {potion.implementationPeriod && (
          <ImplementationPeriod
            implementationPeriod={potion.implementationPeriod}
            refEl={refEls[7]}
          />
        )}
        {potion.researches && potion.researches.length !== 0 && (
          <Researches researches={potion.researches} refEl={refEls[8]} />
        )}
      </Grid>
    </Grid>
  );
};

export default BaseContent;
