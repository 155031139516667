// Срок реализации
import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { PotionDto } from '../../../../api/api';

const ImplementationPeriod: FC<{
  implementationPeriod: PotionDto['implementationPeriod'];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ implementationPeriod, refEl }) => {
  return (
    <Grid item>
      <h3 id={'implementationPeriod'} ref={refEl}>
        Срок реализации
      </h3>
      <Typography>{implementationPeriod}</Typography>
    </Grid>
  );
};

export default ImplementationPeriod;
