// Контрольные показатели
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { BenchmarkDto } from '../../../../api/api';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: '20px',
      fontFamily: 'Montserrat, sans-serif',
      marginTop: '32px',
      marginBottom: '32px',
      fontWeight: 800,
      textTransform: 'uppercase',
      color: theme.palette.text.primary,
    },
    list: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
      color: theme.palette.text.primary,
    },
    item: { marginBottom: '16px' },
    name: {
      borderBottom: '1px dashed #1B1B1B',
      cursor: 'pointer',
    },
    tooltipTitle: {
      color: '#000000',
      fontSize: '16px',
      fontWeight: 500,
      paddingBottom: '4px',
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.secondary,
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    border: '2px solid #DFDFDF',
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    padding: '16px 10px 16px 20px',
    lineHeight: '22px',
    maxWidth: '320px',
  },
}))(Tooltip);

const Benchmarks: FC<{
  benchmarks: BenchmarkDto[];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ benchmarks, refEl }) => {
  const classes = useStyles();
  return (
    <Grid item style={{ marginBottom: '32px' }}>
      <Typography id={'benchmarks'} ref={refEl} className={classes.title}>
        Контрольные показатели
      </Typography>
      <ul className={classes.list}>
        {benchmarks.map((e) => (
          <li key={e.id} className={classes.item} title={'title'}>
            <HtmlTooltip
              title={
                <>
                  <Typography className={classes.tooltipTitle}>
                    {e.name}
                  </Typography>
                  {e.description}
                </>
              }
              interactive
              placement="bottom-end"
            >
              <span className={classes.name}>{e.name}</span>
            </HtmlTooltip>
          </li>
        ))}
      </ul>
    </Grid>
  );
};

export default Benchmarks;
