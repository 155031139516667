// Результат применения
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { EffectDto } from '../../../../api/api';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import arrow from '../../../../assets/images/arrow.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontFamily: 'Montserrat, sans-serif',
      marginTop: '32px',
      marginBottom: '32px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    list: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
    },
    item: { marginBottom: '16px' },
    name: {
      borderBottom: '1px dashed #1B1B1B',
      cursor: 'pointer',
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.secondary,
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    border: '2px solid #DFDFDF',
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    padding: '16px 20px 8px 20px',
    lineHeight: '24px',
  },
}))(Tooltip);

const Effects: FC<{
  effects: EffectDto[];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ effects, refEl }) => {
  const classes = useStyles();

  return (
    <Grid item style={{ marginBottom: '32px' }}>
      <Typography id={'effects'} className={classes.title} ref={refEl}>
        Результат применения
      </Typography>

      <ul className={classes.list}>
        {effects.map((e) => (
          <li key={e.id} className={classes.item}>
            <HtmlTooltip
              title={
                <>
                  Есть ещё 20 зелий <br />с таким эффектом
                  <br />
                  <Link to={`/?effect=${e.id}`}>
                    <img
                      src={arrow}
                      style={{
                        marginTop: '6px',
                        height: '24px',
                        width: '24px',
                      }}
                      alt={''}
                    />
                  </Link>
                </>
              }
              interactive
              placement="bottom-end"
            >
              <span className={classes.name}>{e.name}</span>
            </HtmlTooltip>
          </li>
        ))}
      </ul>
    </Grid>
  );
};

export default Effects;
