// Используемые игровые механики
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { PotionMechanicsDto } from '../../../../api/api';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  title: {
    fontSize: '20px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 800,
    lineHeight: '24px',
    marginTop: '32px',
    marginBottom: '6px',
    textTransform: 'uppercase',
  },
  gridRow: {
    borderBottom: '1px solid #E0E0E0',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  links: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover': {
      color: '#1758FF',
    },
    whiteSpace: 'nowrap',
  },
  category: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.text.secondary,
    paddingLeft: '16px',
  },
}));

const Mechanics: FC<{
  mechanics: PotionMechanicsDto[];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ mechanics, refEl }) => {
  const classes = useStyles();
  return (
    <>
      <Typography id={'mechanics'} ref={refEl} className={classes.title}>
        Используемые игровые механики
      </Typography>
      <Grid item className={classes.root}>
        {mechanics.map((m) => (
          <Grid item key={m.id}>
            <Grid
              container
              justify={'space-between'}
              style={{ minWidth: '596px' }}
              className={classes.gridRow}
            >
              <Grid item xs={3}>
                <Link href={m.link} target={'_blank'} className={classes.links}>
                  {m.name}
                </Link>
              </Grid>
              <Grid item xs={9}>
                <Grid justify={'flex-end'} container>
                  {m.categories?.map((c) => (
                    <Grid
                      key={c.id}
                      item
                      className={
                        classes.category
                      } /*style={{ color: c.color }}*/
                    >
                      {c.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Mechanics;
