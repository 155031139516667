// О чем стоит помнить
// Процесс разработки
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { PotionDto } from '../../../../api/api';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '20px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 800,
      lineHeight: '24px',
    },
    text: {
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      lineHeight: '24px',
      '& > ul > li': {
        paddingBottom: '24px',
      },
      '& > ol > li': {
        paddingBottom: '24px',
      },
    },
  })
);

const SimpleList: FC<{
  titleId: string;
  title: string;
  text: PotionDto['toRemember'] | PotionDto['developmentProcess'];
  refEl: React.RefObject<HTMLDivElement>;
}> = ({ titleId, title, text, refEl }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <h3 id={titleId} ref={refEl} className={classes.title}>
        {title.toUpperCase()}
      </h3>
      <div className={classes.text}>{text && parse(text)}</div>
    </Grid>
  );
};

export default SimpleList;
