import { Button, Grid, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const BackBtn = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Grid
      container
      style={{
        padding: '40px 0',
      }}
      alignItems={'center'}
    >
      <Button
        onClick={() => history.push('/')}
        style={{
          padding: '8px 16px',
          color: theme.palette.text.secondary,
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: 600,
          fontSize: '16px',
          textTransform: 'capitalize',
          lineHeight: '28px',
        }}
      >
        <ArrowBackIosIcon style={{ height: '18px' }} />
        {t('back')}
      </Button>
    </Grid>
  );
};

export default BackBtn;
