import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { PotionDto } from '../../api/api';
import user from '../../assets/images/user.svg';
import clock from '../../assets/images/clock.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '8px 8.5%',
    },
    placeholder: {
      width: '100%',
    },
    text: {
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      fontSize: '14px',
    },
    link: {
      color: '#1B1B1B',
      textDecoration: 'none',
    },
    categoryName: {
      fontSize: '11px',
      fontFamily: 'Montserrat, sans-serif',
      textTransform: 'uppercase',
    },
    potionName: {
      fontSize: '14px',
      fontFamily: 'Montserrat, sans-serif',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginBottom: '12px',
    },
    grid: {
      fontSize: '12px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      marginTop: '13px',
      marginBottom: '11px',
    },
    icon: {
      width: '12px',
      height: '12px',
      marginRight: '4px',
    },
  })
);

const Potions = ({ list }: { list: { data: PotionDto[]; total: number } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} className={classes.root}>
      {list.data.map((item) => {
        return (
          <Grid item xs={3} key={item.id} style={{ marginBottom: '40px' }}>
            <div className={classes.categoryName}>
              <Link to={`/potions/${item.id}`} className={classes.link}>
                {item.category?.name}
              </Link>
            </div>
            <hr />
            <div className={classes.potionName}>
              <Link to={`/potions/${item.id}`} className={classes.link}>
                {item.name}
              </Link>
            </div>
            <div>
              <Link to={`/potions/${item.id}`} className={classes.link}>
                <img
                  className={classes.placeholder}
                  alt="placeholder"
                  src={item.image}
                />
              </Link>
            </div>
            <Grid
              container
              className={classes.grid}
              alignItems="center"
              direction="row"
              spacing={1}
            >
              <Grid item>{t(item.difficulty?.toLowerCase() || '')}</Grid>
              <Grid item xs style={{ whiteSpace: 'nowrap' }}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item style={{ height: '12px' }}>
                    <img
                      className={classes.icon}
                      alt="placeholder"
                      src={user}
                    />
                  </Grid>
                  <Grid item>6-8 человек</Grid>
                </Grid>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <Grid container direction="row" alignItems="center">
                  <Grid item style={{ height: '12px' }}>
                    <img
                      className={classes.icon}
                      alt="placeholder"
                      src={clock}
                    />
                  </Grid>
                  <Grid item>1 месяц</Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.text}>{item.shortDescription}</div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Potions;
