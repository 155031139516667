import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryDto, EffectDto } from '../../api/api';
import { renderFilterSelect } from './utils';

const useStyles = makeStyles(({ palette }) => ({
  all: {
    width: '61px',
    height: '36px',
    fontSize: '16px',
    background: 'transparent',
    borderColor: palette.text.primary,
    borderStyle: 'solid',
    borderWidth: '2px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  select: {
    width: '170px',
    height: '40px',
    fontSize: '16px',
    background: 'transparent',
    color: palette.text.primary,
    borderColor: palette.text.primary,
    borderStyle: 'solid',
    borderWidth: '2px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    paddingLeft: '16px',
    paddingRight: '32px',
    paddingTop: '8px',
    paddingBottom: '8px',
    textOverflow: 'ellipsis',
    '&:hover': {
      borderColor: palette.text.secondary,
    },
    '&:focus': {
      borderRadius: 0,
      background: 'white',
      borderColor: palette.text.primary,
    },
    appearance: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
}));

export type Filter = { id?: number; name?: string; value?: string };
/** Data for selectors */

const FiltersBlock: FC<{
  categoryArr: CategoryDto[];
  effectArr: EffectDto[];
  difficultyArr: Filter[];
  filter: {
    category: CategoryDto['id'];
    effect: EffectDto['id'];
    difficulty: Filter['id'];
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      category: CategoryDto['id'];
      effect: EffectDto['id'];
      difficulty: Filter['id'];
    }>
  >;
}> = ({ categoryArr, effectArr, difficultyArr, filter, setFilter }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const setAllFiltersDefault = () => {
    setFilter({
      category: categoryArr[0].id,
      effect: effectArr[0].id,
      difficulty: difficultyArr[0].id,
    });
  };

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        style={
          isSm
            ? { padding: '8px', marginTop: '40px', marginBottom: '24px' }
            : { padding: '8px 8.5%', marginTop: '40px', marginBottom: '24px' }
        }
      >
        {/** Button All */}
        <Grid item onClick={() => setAllFiltersDefault()}>
          <div
            className={classes.all}
            style={
              filter.category === 0 &&
              filter.effect === 0 &&
              filter.difficulty === 0
                ? {
                    color: theme.palette.background.paper,
                    backgroundColor: theme.palette.text.primary,
                  }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.background.paper,
                  }
            }
          >
            {t('all')}
          </div>
        </Grid>
        {/** Button CATEGORIES */}
        {renderFilterSelect(
          filter.category,
          categoryArr,
          (category: CategoryDto['id']) =>
            setFilter((prev) => ({
              category,
              effect: prev.effect,
              difficulty: prev.difficulty,
            })),
          classes
        )}
        {/** Button EFFECTS */}
        {renderFilterSelect(
          filter.effect,
          effectArr,
          (effect: EffectDto['id']) =>
            setFilter((prev) => ({
              category: prev.category,
              effect,
              difficulty: prev.difficulty,
            })),
          classes
        )}
        {/** Button DIFFICULTY */}
        {renderFilterSelect(
          filter.difficulty,
          difficultyArr,
          (difficulty: Filter['id']) =>
            setFilter((prev) => ({
              category: prev.category,
              effect: prev.effect,
              difficulty,
            })),
          classes
        )}
      </Grid>
    </Grid>
  );
};
export default FiltersBlock;
